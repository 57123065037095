@import 'src/tokens';
@import 'src/mixins';

.CustomAudienceTagSelect__indicator {
  .fa {
    transform: scale(0.8);
    height: 1.5rem;
    width: 1.25rem;
    cursor: pointer;
    transition: unset;
  }
  &:hover {
    cursor: pointer;
    color: $base-text !important;
    .fa {
      background-color: $tint-khaki-200 !important;
      border-radius: $border-radius-s !important;
    }
  }
}
