@import 'src/tokens';

.PixelCreate {
  .TagInput, #pixel-tags, .AutoCompleteInput {
    background-color: $color-grey-800;
  }
  .FormItem__pixel-tags label {
    margin-bottom: -0.75rem;
  }
}
