@import 'src/tokens';
@import 'src/mixins';

.PixelSubscriptionPage {
  height: 100%;
  padding-top: 0;
  display: flex;
  .PixelSubscriptionContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 30px 0 30px;
    width: 100%;
    overflow-y: scroll;
  }
}
#start-date-popper
  .react-datepicker-popper
  .react-datepicker__navigation.react-datepicker__navigation--next,
#end-date-popper
  .react-datepicker-popper
  .react-datepicker__navigation.react-datepicker__navigation--next {
  right: 1px !important;
}

/* Secondary Button */
.btn-secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  border-radius: 0.375rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #b89c64;
}

.btn-secondary:hover {
  color: #9a8254;
}

.btn-secondary:focus {
  outline: 2px solid #b89c64;
  outline-offset: 2px;
}

/* Toggle Switch Styles */
.toggle-checkbox {
  width: 14px !important;
  height: 14px !important;
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  border-width: 0 !important;
  transition: all 200ms ease-in-out;
  z-index: 1;
}

.toggle-checkbox:checked {
  transform: translate(16px, -50%);
}

.toggle-checkbox:checked + .toggle-label {
  background-color: #b89c64;
  border-color: #b89c64;
}

.toggle-checkbox + .toggle-label {
  width: 32px !important;
  height: 16px !important;
  transition: all 200ms ease-in-out;
  background-color: #374151;
  border: 1px solid #4b5563;
}

.TrialDatePickerWrapper {
  .DateRangePicker {
    width: 100%;
    max-width: 100%;
  }
  .DateTargetToggle__button {
    width: 100%;
    margin: 0 10px;
  }
}
